<template>
  <div class="seatsDashboard">
    <vue-scroll :ops="ops">
      <div class="seatsDashboard__title"><b>Sell legs and/or seats</b> on&nbsp;upcoming flights</div>
      <div class="seatsDashboard__list">
        <div
          v-for="item of seats"
          :key="item.id"
          class="seatsDashboard__list-item">
          <div
            class="seatsDashboard__list-item-img"
            :style="{backgroundImage: `url(${item.photo}`}"
          ></div>
          <div class="seatsDashboard__list-item-form">
            <form
              class="form form--seats js-seatsForm whitePicker js-ajaxForm"
              :class="[{'loading' : loading}]"
              data-href="/"
              data-action="seats"
              @submit.prevent="submitHandler"
            >
              <div class="form__inner">
                <Spinner/>
                <div class="form__labelGroup">
                  <label class="form__label form__label--text">
                    <div class="form__icon">
                      <SvgIcon name="departures"/>
                    </div>
                    <div class="form__caption">Departure:</div>
                    <div class="form__field-wrapper">
                    <textarea
                      class="form__field"
                      disabled="disabled"
                      type="textarea"
                      name="from"
                      required="required"
                    >Teterboro Airport (IATA: TEB, ICAO: KTEB, FAA LID: TEB)</textarea>
                    </div>
                  </label>
                  <label class="form__label form__label--text">
                    <div class="form__icon">
                      <SvgIcon name="arrival"/>
                    </div>
                    <div class="form__caption">Arrival:</div>
                    <div class="form__field-wrapper">
                    <textarea
                      class="form__field"
                      disabled="disabled"
                      type="textarea"
                      name="to"
                      placeholder=""
                      required="required"
                    >Opa-Locka Executive Airport (IATA: OPF, ICAO: KOPF, FAA LID: OPF)</textarea>
                    </div>
                  </label>
                  <div class="form__label form__label--date">
                    <div class="form__icon">
                      <SvgIcon name="calendar"/>
                    </div>
                    <div class="form__caption">Dates:</div>
                    <div class="form__field-wrapper">
                      <label>
                        <a-range-picker
                          :placeholder="['start date', 'end date']"
                          separator="-"
                          :allowClear="false"
                          :format="dateFormat"
                          :defaultValue="[moment(item.dates.start, dateFormat), moment(item.dates.end, dateFormat)]"
                          :getCalendarContainer="getPickerContainer"
                          disabled="disabled"
                        />
                      </label>
                    </div>
                  </div>
                </div>
                <div class="form__labelGroup">
                  <div class="form__label form__label--legs">
                    <div class="form__caption">Sell legs:</div>
                    <label class="form__radio-box form__label--radio">
                      <input
                        class="form__radio"
                        type="radio"
                        v-model="legs"
                        value="1"
                      />
                      <span>1</span>
                    </label>
                    <label class="form__radio-box form__label--radio">
                      <input
                        class="form__radio"
                        type="radio"
                        v-model="legs"
                        value="2"
                      />
                      <span>2</span>
                    </label>
                    <label class="form__radio-box form__label--radio">
                      <input
                        class="form__radio"
                        type="radio"
                        v-model="legs"
                        value="3"
                      />
                      <span>3</span>
                    </label>
                    <label class="form__radio-box form__label--radio">
                      <input
                        class="form__radio"
                        type="radio"
                        v-model="legs"
                        value="4"
                      />
                      <span>4</span>
                    </label>
                  </div>
                  <label class="form__label form__label--price price">
                    <div class="form__caption">Sell&nbsp;legs&nbsp;at&nbsp;price:</div>
                    <div class="form__field-wrapper">
                      <input
                        class="form__field"
                        type="text"
                        v-model="legsPrice"
                        v-money="money"
                        placeholder="$600"
                        autocomplete="off"
                        required="required"
                      />
                    </div>
                  </label>
                  <label class="form__label form__label--price price">
                    <div class="form__caption">Sell&nbsp;seats:</div>
                    <div class="form__field-wrapper">
                      <input
                        class="form__field"
                        type="text"
                        v-model="seatsPrice"
                        v-money="money"
                        autocomplete="off"
                        placeholder="$600"
                        required="required"
                      />
                    </div>
                  </label>
                </div>
                <div class="form__labelGroup"><label class="form__label form__label--number">
                  <div class="form__caption">Number of seats for sale:</div>
                  <div class="form__field-wrapper">
                    <input
                      class="form__field js-numberOnly"
                      type="text"
                      v-model="numberSeats"
                      autocomplete="off"
                    />
                  </div>
                  <span v-if="$v.numberSeats.$dirty && !$v.numberSeats.required" class="form__field-invalid">
                    This field is required
                  </span>
                </label>
                  <label class="form__label form__label--price price">
                    <div class="form__caption">Price per seat:</div>
                    <div class="form__field-wrapper">
                      <input
                        class="form__field"
                        type="text"
                        v-model="priceSeat"
                        autocomplete="off"
                      />
                    </div>
                    <span v-if="$v.priceSeat.$dirty && !$v.priceSeat.required" class="form__field-invalid">
                      This field is required
                    </span>
                  </label>
                </div>
              </div>
              <div class="form__label form__label--button">
                <button class="form__button"><span class="text">Submit</span></button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </vue-scroll>
  </div>
</template>

<script>
  import Spinner from '@/components/common/Spinner/Spinner'
  import SvgIcon from '@/components/common/SvgIcon/SvgIcon'
  import { required } from 'vuelidate/lib/validators'
  import { VMoney } from 'v-money'
  import moment from 'moment'
  import './Seats.styl'

  export default {
    name: 'Seats',
    data: () => ({
      dateFormat: 'MM/DD/YYYY',
      legsPrice: '',
      seatsPrice: '',
      legs: '4',
      numberSeats: '',
      priceSeat: '',
      money: {
        decimal: '.',
        thousands: ',',
        prefix: '$ ',
        precision: 2,
        masked: true,
      },
      loading: false,
      ops: {
        rail: {
          size: '3px',
        },
        bar: {
          size: '3px',
          background: 'rgba(0,0,0,0.1)',
        },
      },
    }),
    validations: {
      numberSeats: { required },
      priceSeat: { required },
    },
    directives: { money: VMoney },
    computed: {
      seats () {
        return this.$store.state.dashboard.seats
      },
    },
    mounted () {
      this.$root.fields.changeRadio('.form__radio')
    },
    methods: {
      moment,
      getPickerContainer (trigger) {
        return document.querySelector('.whitePicker')
      },
      submitHandler () {
        if (this.$v.$invalid) {
          this.$v.$touch()
          return false
        }

        const data = {
          // type: this.type,
          // booking: this.booking,
          // from: this.from,
          // to: this.to,
          // date: this.date,
        }

        console.log(data)

        this.loading = true
      },
    },
    components: {
      Spinner,
      SvgIcon,
    },
  }
</script>
